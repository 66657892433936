@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
  }
  
  html,
  body,
  #root {
    height: 100%;
  }
  body {
    background: #e5e6f0;
    -webkit-font-smoothing: antialiased;
  }

  form-control {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    height: 2.714rem;
    font-size: 1rem;
  }